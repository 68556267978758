import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Grid2,
  MenuItem,
  Select,
  Divider,
  Backdrop,
  CircularProgress,
  Stack,
  Chip,
  Tabs,
  Tab,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import presentatioBacground from './res/background-giuribot-extractor.webp';
import { ArrowForward, Remove, Support, Warning } from '@mui/icons-material';
import { DropzoneArea } from 'mui-file-dropzone';
import PDFExtractor from './services/pdf_extractor/pdf_extractor';
import { ChatPromptTemplate } from '@langchain/core/prompts';
import { LLMChain } from 'langchain/chains';

import { ChatOpenAI } from '@langchain/openai';
import { StructuredOutputParser } from '@langchain/core/output_parsers';
import { set, z } from 'zod';
import { SENTENZE_PROMPT, SYSTEM_PROMPT } from './prompts/sentence_prompts';
import {
  DataGridPremium as DataGridPro,
  GridToolbar,
} from '@mui/x-data-grid-premium';
import { LicenseInfo } from '@mui/x-license';
import logo from './res/logo/giuribot_logo_extractor.png';
import {
  checkApiKeyExistsApi,
  checkQuota,
  decreaseApiKeyQuota,
  getLatestSessions,
  getOrCreateApiKey,
  saveSession,
} from './api/key.api';

LicenseInfo.setLicenseKey(process.env['REACT_APP_MUI_LICENSE_KEY'] as string);

// Define the SentenceModel schema

function App() {
  const [apiKey, setApiKey] = useState('');
  const [apiKeyData, setApiKeyData] = useState<{
    quota: number;
    email: string;
    session: string;
  } | null>(null);
  const [isApiKeyValid, setIsApiKeyValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tabValue, setTabValue] = useState('1');

  const [testMode, setTestMode] = useState(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };
  const [toUpdate, setToUpdate] = useState(false);
  const [files, setFiles] = useState<File[] | null>([]);

  const [documentsType, setDocumentsType] = useState<'text' | 'scanned' | ''>(
    'text'
  );

  const [lastSession, setLastSession] = useState<
    {
      id: string;
      results: any[];
      fieldsTemplate: {
        field: string;
        value: string;
      }[];
    }[]
  >([]);

  const [loadingSession, setLoadingSession] = useState(false);

  const [results, setResults] = useState<any[]>([]);
  const [fieldsTemplate, setFieldsTemplate] = useState<
    {
      field: string;
      value: string;
    }[]
  >([
    {
      field: 'anno',
      value: 'Anno della sentenza',
    },
    {
      field: 'numero',
      value: 'Numero della sentenza',
    },
    {
      field: 'oggetto',
      value: 'Oggetto della sentenza',
    },
    {
      field: 'tipologiaAtto',
      value: "Tipologia dell'atto",
    },
    {
      field: 'soggettoRicorrente',
      value: 'Soggetto ricorrente',
    },
    {
      field: 'soggettoResistente',
      value: 'Soggetto resistente',
    },
    {
      field: 'numeroDiRegistro',
      value: 'Numero di registro',
    },
  ]);

  const handleFieldChange = (field: string, index: number) => {
    setFieldsTemplate((prevFields) => {
      let arr = [...prevFields];
      arr[index] = {
        field: field,
        value: arr[index].value,
      };

      return arr;
    });
    setToUpdate(true);
  };

  const handleApiKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApiKey(event.target.value);
  };

  const handleApiKeySubmit = () => {
    setIsLoading(true);

    if (!apiKey.trim()) {
      alert('Devi inserire una chiave API valida');
      setIsLoading(false);
      return;
    }

    checkApiKeyExistsApi(apiKey).then((response) => {
      if (response.exists) {
        setIsApiKeyValid(true);
        setApiKeyData({ ...response.value, session: new Date().toISOString() });
      } else {
        alert("Chiave API non valida. Contatta l'amministratore.");
      }
      setIsLoading(false);
    });
  };

  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.files && event.target.files[0]) {
  //     setFile(event.target.files);
  //   }
  // };

  const handleFileUpload = () => {
    if (files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target?.result;
        console.log('File content:', content);
        // Process the file content and create JSON object
      };
      //reader.readAsText(file);
    }
  };

  const testPDFExtractor = async () => {
    setIsLoading(true);
    const SentenceModel = z.object({
      anno: z.string().describe('Anno della sentenza'),
      numero: z.string().describe('Numero della sentenza'),
      oggetto: z.string().describe('Oggetto della sentenza'),
      tipologiaAtto: z.string().describe("Tipologia dell'atto"),
      soggettoRicorrente: z.string().describe('Soggetto ricorrente'),
      soggettoResistente: z.string().describe('Soggetto resistente'),
      numeroDiRegistro: z.string().describe('Numero di registro'),
    });
    const dynamicSchema = fieldsTemplate.reduce((acc, field) => {
      acc[field.field] = z.string().describe(field.value);
      return acc;
    }, {} as Record<string, z.ZodString>);

    const DynamicSentenceModel = z.object(dynamicSchema);
    // Prompts

    // Create the prompt template
    const callSentencePrompt = ChatPromptTemplate.fromMessages([
      { role: 'system', content: SYSTEM_PROMPT },
      {
        role: 'user',
        content: `${SENTENZE_PROMPT} \n Ritorna l'output con la seguente struttura: {output}. \n Produce un JSON valido.`,
      },
    ]);

    // Create the output parser
    const parserSentence =
      StructuredOutputParser.fromZodSchema(DynamicSentenceModel);

    // Combine the prompt, LLM, and parser into a chain
    const llm = new ChatOpenAI({
      model: 'gpt-4o-mini',
      apiKey: process.env['REACT_APP_OPENAI_API_KEY'],
    }); // Use "gpt-4o-mini" if needed

    const chain = callSentencePrompt.pipe(llm).pipe(parserSentence);
    let index = 0;
    const arr = [...JSON.parse(JSON.stringify(results))];

    try {
      for (const file of files!) {
        try {
          const quotaAvailable = await checkQuota(apiKey);

          if (quotaAvailable < 1) {
            alert('Quota esaurita');
            setIsLoading(false);
            break;
          }

          setApiKeyData((prevData) => {
            if (prevData) {
              return { ...prevData, quota: quotaAvailable };
            }
            return prevData;
          });
        } catch (e) {
          setIsLoading(false);
          alert('Qualcosa è andato storto');
          break;
        }
        index = index + 1;
        const pdfExtractor = new PDFExtractor(file);
        const text =
          documentsType === 'scanned'
            ? await pdfExtractor.extractTextWithOCR()
            : await pdfExtractor.extractText();
        const result = await chain.invoke({
          sentenza: text,
          output: parserSentence.getFormatInstructions(),
        });

        try {
          await decreaseApiKeyQuota(apiKey, 1);
        } catch (e) {
          alert('Qualcosa è andato storto');
          break;
        }

        setApiKeyData((prevData) => {
          if (prevData) {
            return { ...prevData, quota: prevData.quota - 1 };
          }
          return prevData;
        });

        console.log('Result : ', result);

        arr.push({ id: `${file.name}-${index}`, ...result });
        setResults(JSON.parse(JSON.stringify(arr)));

        console.log('Processed document:', result);
      }
    } catch (e) {
      if (arr.length > 0) {
        let arrResults = [...JSON.parse(JSON.stringify(results))];
        for (let i = 0; i < arr.length; i++) {
          let objKeys = Object.keys(arr[i]);
          objKeys.forEach((key) => {
            if (key.trim() === '') {
              delete arr[i][key];
            }
          });
        }

        try {
          await saveSession(apiKey, apiKeyData?.session || '', {
            results: arrResults,
            fieldsTemplate: fieldsTemplate,
          });
        } catch (e) {}
      }

      setIsLoading(false);
    }

    let arrResults = [...JSON.parse(JSON.stringify(results))];
    for (let i = 0; i < arr.length; i++) {
      let objKeys = Object.keys(arr[i]);
      objKeys.forEach((key) => {
        if (key.trim() === '') {
          delete arr[i][key];
        }
      });
    }

    try {
      await saveSession(apiKey, apiKeyData?.session || '', {
        results: arrResults,
        fieldsTemplate: fieldsTemplate,
      });
    } catch (e) {}

    setResults(arr);
    setIsLoading(false);
    //console.log('Extracted text:', text);
  };

  const fetchLastSession = async () => {
    setLoadingSession(true);
    const latestSessions = await getLatestSessions(apiKey);

    setLastSession(latestSessions);
    setLoadingSession(false);
  };

  useEffect(() => {
    if (isApiKeyValid && apiKey !== '') {
      console.log(' Api ', apiKey);
      fetchLastSession();
    }
  }, [isApiKeyValid]);

  // useEffect(() => {
  //   let arr = [...JSON.parse(JSON.stringify(results))];
  //   for (let i = 0; i < arr.length; i++) {
  //     let objKeys = Object.keys(arr[i]);
  //     objKeys.forEach((key) => {
  //       if (key.trim() === '') {
  //         delete arr[i][key];
  //       }
  //     });
  //   }

  //   setResults(arr);
  // }, [results]);

  const fetchUserIP = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip; // Returns the IP address
    } catch (error) {
      console.error('Error fetching IP:', error);
      return null;
    }
  };

  return (
    <Box
      sx={{
        height: '100vh',
      }}
    >
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme: any) => theme.zIndex.drawer + 99999,
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!isApiKeyValid ? (
        <Grid2
          container
          sx={{
            height: '100%',
          }}
          spacing={4}
        >
          <Grid2
            size={6}
            sx={{
              height: '100%',
            }}
          >
            <Box
              sx={{
                height: '100%',
                backgroundImage: `url(${presentatioBacground})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                width: '100%',
              }}
            ></Box>
          </Grid2>

          <Grid2
            size={6}
            sx={{
              height: '100%',
            }}
          >
            <Box
              sx={{
                height: '100%',
                px: 4,
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height={'100%'}
              >
                <img src={logo} alt="GiuriBot" style={{ width: '300px' }} />
                {/* <Typography variant="h3" mb={2} textAlign={'center'}>
                  GiuriBot Extractor
                </Typography> */}
                <Typography textAlign={'center'} mt={2}>
                  {' '}
                  Benvenuti in GiuriBot Extractor, uno strumento innovativo che
                  converte i documenti in tabelle con colonne. Carica il tuo
                  file e lascia che GiuriBot faccia il resto!
                </Typography>

                <TextField
                  label="Chiave di accesso"
                  variant="outlined"
                  value={apiKey}
                  onChange={handleApiKeyChange}
                  margin="normal"
                  sx={{
                    width: '300px',
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleApiKeySubmit();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <Button
                        onClick={handleApiKeySubmit}
                        size="large"
                        disableElevation
                        variant="contained"
                        color="primary"
                      >
                        <ArrowForward />
                      </Button>
                    ),
                  }}
                />

                <Button
                  size="large"
                  disableElevation
                  variant="contained"
                  color="primary"
                  onClick={() => (window.location.pathname = '/payment.html')}
                  style={{ marginTop: '16px' }}
                >
                  Crea account
                </Button>

                <Button
                  size="large"
                  disableElevation
                  variant="outlined"
                  color="primary"
                  onClick={async () => {
                    const userIP = await fetchUserIP(); // Fetch the user's IP

                    if (userIP) {
                      const apiKeyData = await getOrCreateApiKey(userIP); // Pass the IP to your function
                      setApiKey(apiKeyData.apiKey);
                      setIsApiKeyValid(true);
                      setApiKeyData({
                        ...apiKeyData,
                        session: new Date().toISOString(),
                      } as {
                        quota: number;
                        email: string;
                        session: string;
                      });
                    } else {
                      alert('Unable to fetch user IP');
                    }
                  }}
                  style={{ marginTop: '16px' }}
                >
                  Prova l'applicazione
                </Button>
              </Box>
            </Box>
          </Grid2>
        </Grid2>
      ) : (
        <Box
          sx={{
            p: 4,
          }}
        >
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Box>
              <Typography variant="h5">Benvenuto!</Typography>
            </Box>

            <Stack direction={'row'} spacing={1}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  window.location.href = 'mailto:twinsoftai@gmail.com';
                }}
              >
                <Support />
              </Button>

              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setIsApiKeyValid(false);
                  setApiKey('');
                }}
              >
                Logout
              </Button>
            </Stack>
          </Stack>

          <Box sx={{ width: '100%', typography: 'body1' }} mt={2}>
            <Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <Box
                  sx={{
                    width: '100%',
                    maxWidth: 600,
                    bgcolor: 'background.paper',
                    boxShadow: 1,
                    borderRadius: 2,
                    p: 1,
                    textAlign: 'center',
                  }}
                >
                  <Typography variant="h6">
                    Quota: <code>{apiKeyData?.quota}</code> documenti
                  </Typography>
                </Box>
              </Box>
            </Box>
            <TabContext value={tabValue}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  textAlign: 'center',
                }}
              >
                <TabList
                  onChange={handleTabChange}
                  aria-label="tabs example"
                  sx={{
                    justifyContent: 'center',
                  }}
                >
                  <Tab label="Documenti" value="1" />
                  <Tab label="Risultati" value="2" />
                  <Tab label="Storico" value="3" />
                </TabList>
              </Box>
              <TabPanel value="3">
                {lastSession.length > 0 ? (
                  <Stack spacing={2}>
                    {lastSession.map((session, index) => (
                      <Box
                        key={index}
                        onClick={() => {
                          if (
                            window.confirm(
                              `Vuoi caricare i risultati della sessione (${session.id})?`
                            )
                          ) {
                            setResults(session.results);
                            setFieldsTemplate(session.fieldsTemplate);
                            setApiKeyData((prevData) => {
                              if (prevData) {
                                return { ...prevData, session: session.id };
                              }
                              return prevData;
                            });
                            setTabValue('2');
                          }
                        }}
                        sx={{
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                          padding: '16px',
                          backgroundColor: '#f9f9f9',
                          cursor: 'pointer',
                        }}
                      >
                        <Typography variant="h6">
                          Sessione {session.id}
                        </Typography>
                      </Box>
                    ))}
                  </Stack>
                ) : (
                  <Typography variant="body1" mt={2}>
                    Nessun risultato da visualizzare
                  </Typography>
                )}
              </TabPanel>
              <TabPanel value="1">
                {(files as File[])?.length > 0 && fieldsTemplate.length > 0 ? (
                  <Button
                    fullWidth
                    variant="contained"
                    disableElevation
                    sx={{
                      mt: 4,
                    }}
                    onClick={() => {
                      testPDFExtractor();
                      setTabValue('2');
                    }}
                    size="large"
                    endIcon={<ArrowForward />}
                  >
                    Inizia estrazione
                  </Button>
                ) : null}
                <Box sx={{ mt: 2, mb: 2 }}>
                  <Typography variant="body2" color="warning">
                    Attenzione: se i PDF sono immagini scansionate, il processo
                    potrebbe richiedere molto più tempo.
                    <Warning
                      sx={{
                        ml: 1,
                        verticalAlign: 'middle',
                        fontSize: '1rem',
                      }}
                    />
                  </Typography>
                </Box>
                <Box sx={{}}>
                  <Box>
                    <Typography variant="body2">
                      Tipo di documento PDF:
                    </Typography>
                    <Select
                      value={documentsType}
                      onChange={(e) =>
                        setDocumentsType(
                          e.target.value as 'text' | 'scanned' | ''
                        )
                      }
                      fullWidth
                    >
                      <MenuItem value="text">Testo</MenuItem>
                      <MenuItem value="scanned">Scansionato</MenuItem>
                    </Select>
                  </Box>

                  {documentsType !== '' ? (
                    <Box mt={2}>
                      <DropzoneArea
                        initialFiles={files as File[]}
                        maxFileSize={100 * 1024 * 1024} // 10 MB in bytes
                        dropzoneText="Qui puoi caricare i documenti PDF"
                        filesLimit={300}
                        acceptedFiles={['application/pdf']}
                        onChange={(files) => {
                          console.log('Files:', files);
                          setFiles((prevFiles) => {
                            const newFiles = files.filter(
                              (file) =>
                                !prevFiles?.some(
                                  (prevFile) => prevFile.name === file.name
                                )
                            );
                            return [...(prevFiles || []), ...newFiles];
                          });
                        }}
                        fileObjects={[]}
                        showPreviews={false}
                        showFileNamesInPreview={false}
                        showPreviewsInDropzone={false}
                        showFileNames={false}
                      />
                    </Box>
                  ) : null}

                  <Stack direction={'row'} mt={2} spacing={2}>
                    {files?.map((file, index) => (
                      <Chip
                        key={index}
                        label={file.name}
                        onDelete={() => {
                          setFiles(
                            (prevFiles) =>
                              prevFiles?.filter((_, i) => i !== index) || null
                          );
                        }}
                      />
                    ))}
                  </Stack>
                </Box>
                <Grid2
                  mt={2}
                  container
                  spacing={2}
                  sx={{
                    boxSizing: 'border-box',
                  }}
                >
                  {/* Left Side: Procedural Input Fields */}
                  <Grid2 size={6}>
                    <Typography mb={2}>Colonne Personalizzate</Typography>
                    {fieldsTemplate.map((field, index) => (
                      <Box
                        key={index}
                        mb={2}
                        display="flex"
                        alignItems="center"
                      >
                        <TextField
                          type={'text'}
                          value={field.field}
                          onChange={(el) =>
                            handleFieldChange(el.currentTarget.value, index)
                          }
                          placeholder="Nome del campo"
                          fullWidth
                        />
                        <TextField
                          sx={{ ml: 1 }}
                          value={field.value}
                          onChange={(el) => {
                            const value = el.target.value;
                            setFieldsTemplate((prevFields) => {
                              let arr = [...prevFields];
                              arr[index] = {
                                field: arr[index].field,
                                value: value,
                              };
                              return arr;
                            });
                            setToUpdate(true);
                          }}
                          fullWidth
                          placeholder="Descrizione del campo"
                        />
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => {
                            setFieldsTemplate((prevFields) =>
                              prevFields.filter((_, i) => i !== index)
                            );
                            setToUpdate(true);
                          }}
                          sx={{ ml: 2 }}
                        >
                          <Remove />
                        </Button>
                      </Box>
                    ))}
                    <Button
                      variant="outlined"
                      onClick={() => {
                        const newField = { field: '', value: '' };
                        setFieldsTemplate((prevFields) => [
                          ...prevFields,
                          newField,
                        ]);
                      }}
                    >
                      Aggiungi Colonna
                    </Button>
                  </Grid2>

                  {/* Right Side: JSON Display */}
                  <Grid2 size={6}>
                    <Typography variant="h6" mb={2}>
                      Anteprima Modello
                    </Typography>
                    <Box
                      sx={{
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        padding: '16px',
                        backgroundColor: '#f9f9f9',
                        overflow: 'auto',
                      }}
                    >
                      <pre>
                        {JSON.stringify(
                          fieldsTemplate.reduce(
                            (acc: { [key: string]: string }, field) => {
                              acc[field.field] = field.value;
                              return acc;
                            },
                            {}
                          ),
                          null,
                          2
                        )}
                      </pre>
                    </Box>
                  </Grid2>
                </Grid2>
                {/* Add content for Tab 1 here */}
              </TabPanel>
              <TabPanel value="2">
                <Stack direction={'row'} justifyContent={'end'}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      console.log('Results:', results);
                      console.log('Fields:', fieldsTemplate);
                      setIsLoading(true);

                      let arr = [...JSON.parse(JSON.stringify(results))];
                      for (let i = 0; i < arr.length; i++) {
                        let objKeys = Object.keys(arr[i]);
                        objKeys.forEach((key) => {
                          if (key.trim() === '') {
                            delete arr[i][key];
                          }
                        });
                      }

                      saveSession(apiKey, apiKeyData?.session || '', {
                        results: arr,
                        fieldsTemplate: fieldsTemplate,
                      })
                        .then(() => {
                          setIsLoading(false);
                        })
                        .catch((e) => {
                          console.log(e);
                          setIsLoading(false);
                          alert('Qualcosa è andato storto');
                        });
                    }}
                  >
                    Salva
                  </Button>
                </Stack>
                <DataGridPro
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  columns={fieldsTemplate.map((field) => ({
                    field: field.field,
                    headerName: field.field,
                    width: 150,
                  }))}
                  rows={results || []}
                  autoHeight
                />
                {/* Add content for Tab 2 here */}
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default App;
