import {
  addDoc,
  DocumentData,
  DocumentReference,
  getFirestore,
  collection,
  setDoc,
  orderBy,
  limit,
  query,
  getDocs,
} from 'firebase/firestore';
import { app } from '../services/firebaseSetUp';
import { getDoc, doc } from 'firebase/firestore';
import { runTransaction } from 'firebase/firestore';

const db = getFirestore(app);

export const createApiKey = async (ip: string): Promise<string> => {
  const apiKey = ip;
  const apiKeyDocRef = doc(db, 'apiKeys', apiKey);

  const apiKeyDoc = await getDoc(apiKeyDocRef);
  if (apiKeyDoc.exists()) {
    throw new Error('API key already exists');
  }

  await setDoc(apiKeyDocRef, {
    ip,
    quota: 5,
    apiKey,
    createdAt: new Date(),
    updatedAt: new Date(),
  });

  return apiKey;
};
export const getOrCreateApiKey = async (ip: string): Promise<DocumentData> => {
  const apiKey = ip;
  const apiKeyDocRef = doc(db, 'apiKeys', apiKey);

  const apiKeyDoc = await getDoc(apiKeyDocRef);
  if (apiKeyDoc.exists()) {
    return apiKeyDoc.data();
  }

  const newApiKeyData = {
    ip,
    quota: 5,
    apiKey,
    createdAt: new Date(),
    updatedAt: new Date(),
  };

  await setDoc(apiKeyDocRef, newApiKeyData);

  return newApiKeyData;
};

const generateApiKey = (ip: string): string => {
  // Simple example of generating an API key based on IP
  return `${ip}-${Date.now()}`;
};

export const checkApiKeyExistsApi = async (
  apiKey: string
): Promise<{ exists: boolean; value?: any }> => {
  const apiKeyDocRef = doc(db, 'apiKeys', apiKey);
  const apiKeyDoc = await getDoc(apiKeyDocRef);
  return {
    exists: apiKeyDoc.exists(),
    value: apiKeyDoc.exists() ? apiKeyDoc.data() : null,
  };
};

export const checkQuota = async (apiKey: string): Promise<number> => {
  const apiKeyDocRef = doc(db, 'apiKeys', apiKey);
  const apiKeyDoc = await getDoc(apiKeyDocRef);

  if (!apiKeyDoc.exists()) {
    throw new Error('API key does not exist');
  }

  const data = apiKeyDoc.data();
  return data.quota || 0;
};

export const decreaseApiKeyQuota = async (
  apiKey: string,
  amount: number
): Promise<void> => {
  const apiKeyDocRef = doc(db, 'apiKeys', apiKey);

  await runTransaction(db, async (transaction) => {
    const apiKeyDoc = await transaction.get(apiKeyDocRef);

    if (!apiKeyDoc.exists()) {
      throw new Error('API key does not exist');
    }

    const currentData = apiKeyDoc.data();
    const newQuota = (currentData.quota || 0) - amount;

    transaction.update(apiKeyDocRef, {
      quota: newQuota >= 0 ? newQuota : 0,
    });
  });
};

export const saveSession = async (
  apiKey: string,
  sessionId: string,
  sessionData: any
): Promise<void> => {
  const apiKeyDocRef = doc(db, 'apiKeys', apiKey);
  const sessionDocRef = doc(apiKeyDocRef, 'sessions', sessionId);

  await runTransaction(db, async (transaction) => {
    const sessionDoc = await transaction.get(sessionDocRef);

    if (sessionDoc.exists()) {
      transaction.update(sessionDocRef, {
        ...sessionData,
        updatedAt: new Date(),
      });
    } else {
      transaction.set(sessionDocRef, {
        ...sessionData,
        createdAt: new Date(),
        updatedAt: new Date(),
      });
    }
  });
};

export const getLatestSessions = async (
  apiKey: string
): Promise<
  {
    id: string;
    results: any[];
    fieldsTemplate: {
      field: string;
      value: string;
    }[];
  }[]
> => {
  const apiKeyDocRef = doc(db, 'apiKeys', apiKey);
  const sessionsCollectionRef = collection(apiKeyDocRef, 'sessions');
  const sessionsQuery = query(
    sessionsCollectionRef,
    orderBy('updatedAt', 'desc'),
    limit(10)
  );

  const querySnapshot = await getDocs(sessionsQuery);
  return querySnapshot.docs.map(
    (doc) =>
      ({ ...doc.data(), id: doc.id } as {
        id: string;
        results: any[];
        fieldsTemplate: {
          field: string;
          value: string;
        }[];
      })
  );
};
